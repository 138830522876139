<template>
  <div class="schoolItem">
    <h2>{{ queryInfo.schoolName }}</h2>
    <p v-for="(item, index) in descList" :key="index">&emsp;&emsp;{{ item }}</p>
  </div>
</template>

<script>
import { schoolInfo } from '../../api'
export default {
  data () {
    return {
      queryInfo: {
        id: this.$route.query.id,
        schoolName: '',
        desc: ''
      },
      descList: []

    }
  },
  created () {
    this.getDescList()
  },
  methods: {
    getDescList () {
      schoolInfo(this.queryInfo.id).then((res) => {
        this.queryInfo.schoolName = res.data.data.schoolName
        this.queryInfo.desc = res.data.data.description
        this.descList = this.queryInfo.desc.split('\n')

        console.log(this.descList)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.schoolItem {
  padding: 70px 0px;
  line-height: 30px;
  font-size: 16px;
  p {
    margin-bottom: 30px;
  }
}
</style>
